/* Styles for the entire modal container */
.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url("./assets/go1.JPG");
    background-size: cover;
    z-index: 1000; /* Ensure it's above other content */
    cursor: cell;
    background-position: center;
  }
  
  /* Styles for the modal content */
  .modal-content {
    border-radius: 5px;
    max-width: 80%;
    text-align: center;
    position: relative;
    cursor: cell;
  }